<template>
  <div class="form-container">
    <label>Jenjang Soal</label>
    <br />

    <b-form inline>
      <b-form-select
        v-model="inputData.tryout_level_id"
        :options="levelOptions"
        required
        disabled
        class="mrr-22 mrb-5"
      ></b-form-select>

      <b-form-select
        v-model="inputData.tryout_subject_id"
        :options="subjectOptions"
        required
        disabled
        class="mrb-5"
      ></b-form-select> </b-form
    ><br />

    <label>Level Soal</label>
    <br />

    <b-form-select
      v-model="inputData.level"
      :options="levels"
      required
      class="mrr-22 mrb-5"
    ></b-form-select>
    <br /><br />

    <label>Judul Soal</label><br />
    <b-form-input placeholder="Title..." v-model="inputData.title"></b-form-input><br /><br />

    <label>Pertanyaan (include latex mode)</label><br />
    <b-form-textarea
      v-model="inputData.question"
      placeholder="Enter something..."
      rows="6"
      max-rows="6"
    ></b-form-textarea
    ><br /><br />

    <label>Preview Soal</label><br />
    <div
      v-katex="{expression: inputData.question ? inputData.question : ' ', options: { macros: {' ': '\\space '} }}"
      style="min-height: 50px; background: #e9ecef;"
    ></div
    ><br /><br />

    <div class="upload-image mt-4" v-show="inputData.gallery_url">
      <div class="upload-image__item mr-4">
        <div class="upload-image__top">
          <img :src="inputData.gallery_url" alt="img" style="max-height:108px" />
          <div class="img-remove" v-if="inputData.gallery_url" @click="removePicture(0)">
            X Remove
          </div>
        </div>
      </div>

      <!-- <div class="upload-image__item c-pointer">
        <img src="/img/add-image.png" alt="" />
      </div> -->
    </div>
    <input type="file" name="file1" id="file1" class="hidden-input" @change="changePicture(0)" />
    <label for="file1" class="file-upload">
      <fa-icon icon="upload" class="my-icon" />
      Upload Gambar Soal
    </label>
    <br /><br />

    <label>Gambar pada Soal</label><br />
    <div class="flex">
      <div class="answer-index">
        <span> A </span>
      </div>

      <div class="flex-1">
        <b-form-textarea
          v-model="inputData.option1.option"
          placeholder="Answer..."
          rows="3"
        ></b-form-textarea
        ><br />
        <label>Preview</label><br />
        <div
          v-katex="{expression: inputData.option1.option ? inputData.option1.option : ' ', options: { macros: {' ': '\\space '} }}"
          style="min-height: 50px; background: #e9ecef;"
        ></div>
        <div class="upload-image mt-4" v-show="inputData.option1.picture_url">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top">
              <img :src="inputData.option1.picture_url" alt="img" style="max-height:108px" />
              <div class="img-remove" v-if="inputData.option1.picture_url" @click="removePicture(1)">
                X Remove
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          name="ansfile1"
          id="ansfile1"
          class="hidden-input"
          @change="changePicture(1)"
        />
        <label for="ansfile1" class="file-upload">
          <fa-icon icon="upload" class="my-icon" />
          Upload Gambar Pilihan Jawaban
        </label>
      </div>
    </div>
    <br /><br />
    <div class="flex">
      <div class="answer-index">
        <span> B </span>
      </div>

      <div class="flex-1">
        <b-form-textarea
          v-model="inputData.option2.option"
          placeholder="Answer..."
          rows="3"
        ></b-form-textarea
        ><br />
        <label>Preview</label><br />
        <div
          v-katex="{expression: inputData.option2.option ? inputData.option2.option : ' ', options: { macros: {' ': '\\space '} }}"
          style="min-height: 50px; background: #e9ecef;"
        ></div>
        <div class="upload-image mt-4" v-show="inputData.option2.picture_url">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top">
              <img :src="inputData.option2.picture_url" alt="img" style="max-height:108px" />
              <div class="img-remove" v-if="inputData.option2.picture_url" @click="removePicture(2)">
                X Remove
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          name="ansfile2"
          id="ansfile2"
          class="hidden-input"
          @change="changePicture(2)"
        />
        <label for="ansfile2" class="file-upload">
          <fa-icon icon="upload" class="my-icon" />
          Upload Gambar Pilihan Jawaban
        </label>
      </div>
    </div>
    <br /><br />

    <div class="flex">
      <div class="answer-index">
        <span> C </span>
      </div>

      <div class="flex-1">
        <b-form-textarea
          v-model="inputData.option3.option"
          placeholder="Answer..."
          rows="3"
        ></b-form-textarea
        ><br />
        <label>Preview</label><br />
        <div
          v-katex="{expression: inputData.option3.option ? inputData.option3.option : ' ', options: { macros: {' ': '\\space '} }}"
          style="min-height: 50px; background: #e9ecef;"
        ></div>
        <div class="upload-image mt-4" v-show="inputData.option3.picture_url">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top">
              <img :src="inputData.option3.picture_url" alt="img" style="max-height:108px" />
              <div class="img-remove" v-if="inputData.option3.picture_url" @click="removePicture(3)">
                X Remove
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          name="ansfile3"
          id="ansfile3"
          class="hidden-input"
          @change="changePicture(3)"
        />
        <label for="ansfile3" class="file-upload">
          <fa-icon icon="upload" class="my-icon" />
          Upload Gambar Pilihan Jawaban
        </label>
      </div>
    </div>
    <br /><br />

    <div class="flex">
      <div class="answer-index">
        <span> D </span>
      </div>

      <div class="flex-1">
        <b-form-textarea
          v-model="inputData.option4.option"
          placeholder="Answer..."
          rows="3"
        ></b-form-textarea
        ><br />
        <label>Preview</label><br />
        <div
          v-katex="{expression: inputData.option4.option ? inputData.option4.option : ' ', options: { macros: {' ': '\\space '} }}"
          style="min-height: 50px; background: #e9ecef;"
        ></div>
        <div class="upload-image mt-4" v-show="inputData.option4.picture_url">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top">
              <img :src="inputData.option4.picture_url" alt="img" style="max-height:108px" />
              <div class="img-remove" v-if="inputData.option4.picture_url" @click="removePicture(4)">
                X Remove
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          name="ansfile4"
          id="ansfile4"
          class="hidden-input"
          @change="changePicture(4)"
        />
        <label for="ansfile4" class="file-upload">
          <fa-icon icon="upload" class="my-icon" />
          Upload Gambar Pilihan Jawaban
        </label>
      </div>
    </div>
    <br /><br />

    <div class="flex">
      <div class="answer-index">
        <span> E </span>
      </div>

      <div class="flex-1">
        <b-form-textarea
          v-model="inputData.option5.option"
          placeholder="Answer..."
          rows="3"
        ></b-form-textarea
        ><br />
        <label>Preview</label><br />
        <div
          v-katex="{expression: inputData.option5.option ? inputData.option5.option : ' ', options: { macros: {' ': '\\space '} }}"
          style="min-height: 50px; background: #e9ecef;"
        ></div>
        <div class="upload-image mt-4" v-show="inputData.option5.picture_url">
          <div class="upload-image__item mr-4">
            <div class="upload-image__top">
              <img :src="inputData.option5.picture_url" alt="img" style="max-height:108px" />
              <div class="img-remove" v-if="inputData.option5.picture_url" @click="removePicture(5)">
                X Remove
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          name="ansfile5"
          id="ansfile5"
          class="hidden-input"
          @change="changePicture(5)"
        />
        <label for="ansfile5" class="file-upload">
          <fa-icon icon="upload" class="my-icon" />
          Upload Gambar Pilihan Jawaban
        </label>
      </div>
    </div>
    <br /><br />

    <label>Jawaban Benar</label><br />
    <b-form inline>
      <b-form-select
        v-model="inputData.answer"
        :options="correctAns"
        required
      ></b-form-select>
    </b-form>
    <br /><br />

    <label>Pembahasan (include latex mode)</label><br />
    <b-form-textarea placeholder="Type Here..." rows="4" v-model="inputData.solution"></b-form-textarea
    ><br /><br />
    <div
      v-katex="{expression: inputData.solution ? inputData.solution : ' ', options: { macros: {' ': '\\space '} }}"
      style="min-height: 50px; background: #e9ecef;"
    ></div
    ><br /><br />

    <b-button size="lg" variant="primary" class="w-100" v-if="showLoading">
      <b-spinner small></b-spinner>
      <span class="sr-only">Loading...</span>
    </b-button>
    <b-button size="lg" variant="primary" class="w-100" v-else @click="save">SIMPAN SOAL</b-button>
    
    <br /><br />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      showLoading: false,
      inputData: {
        title: "",
        tryout_level_id: null,
        tryout_subject_id: null,
        id: null,
        question: "",
        answer: 1,
        gallery: "",
        gallery_url: "",
        removeOld: 0,
        option1: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option2: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option3: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option4: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        },
        option5: {
          option: "",
          picture: null,
          picture_url: "",
          removeOld: 0
        }, 
        solution: "",
        level: 'easy'
      },
      levelOptions: [
        { text: "Jenjang", value: null }
      ],

      subjectOptions: [
        { text: "Mata Pelajaran", value: null }
      ],

      correctAns: [
        { text: "A", value: 1 },
        { text: "B", value: 2 },
        { text: "C", value: 3 },
        { text: "D", value: 4 },
        { text: "E", value: 5 },
      ],
      levels: [
        { text: "Easy", value: "easy" },
        { text: "Medium", value: "medium" },
        { text: "Hard", value: "hard" }
      ]
    };
  },
  mounted() {
    this.tryoutQuestionDetail();
  },
  methods: {
    ...mapActions({
      getTryoutLevelList: types.GET_TRYOUT_LEVEL_LIST,
      getTryoutSubjectList: types.GET_TRYOUT_SUBJECT_LIST,
      updateTryoutQuestion: types.UPDATE_TRYOUT_QUESTION,
      getTryoutQuestionDetail: types.GET_TRYOUT_QUESTION_DETAIL
    }),
    tryoutQuestionDetail() {
      this.getTryoutQuestionDetail(this.$route.params.id)
      .then(response => {
        var res = response[0];
        this.levelOptions = [{text: res.tryout_level, value: res.tryout_level_id}];
        this.subjectOptions = [{text: res.tryout_subject, value: res.tryout_subject_id}];
        var question = {
          tryout_level_id: res.tryout_level_id,
          tryout_subject_id: res.tryout_subject_id,
          question: res.question,
          title: res.title,
          solution: res.solution,
          level: res.level,
          answer: res.answer,
          id: res.id,
          gallery: null,
          gallery_url: res.gallery,
          removeOld: 0,
          option1: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option2: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option3: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option4: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          },
          option5: {
            option: "",
            picture: null,
            picture_url: "",
            removeOld: 0
          }
        }
        for (var i = 0; i < res.options_json.length; i++) {
          question['option'+res.options_json[i].key].option = res.options_json[i].option
          question['option'+res.options_json[i].key].picture = null;
          question['option'+res.options_json[i].key].picture_url = res.options_json[i].picture;
          question['option'+res.options_json[i].key].removeOld = 0
        }
        this.inputData = question;
      }).catch(error => {
        this.showMessageBox(error, "Failed", "success");
      })
    },
    changePicture(numb) {
      var fileData = event.target.files[0];
      if(numb == 0) {
        this.inputData.gallery = fileData;
        this.inputData.gallery_url = URL.createObjectURL(fileData);
        this.inputData.removeOld = 0;
      } else {
        this.inputData['option'+numb].picture = fileData;
        this.inputData['option'+numb].picture_url = URL.createObjectURL(fileData);
        this.inputData['option'+numb].removeOld = 0;
      }
    },
    removePicture(numb) {
      if(numb == 0) {
        this.inputData.gallery_url = "";
        this.inputData.gallery = null;
        this.inputData.removeOld = 1;
      } else {
        this.inputData['option'+numb].picture_url = "";
        this.inputData['option'+numb].picture = null;
        this.inputData['option'+numb].removeOld = 1;
      }
    },
    save() {
      this.showLoading = true;
      let newFormData = new FormData();
      newFormData.append("question", this.inputData.question);
      newFormData.append("answer", this.inputData.answer);
      newFormData.append("tryout_subject_id", this.inputData.tryout_subject_id);
      newFormData.append("tryout_level_id", this.inputData.tryout_level_id);
      newFormData.append("solution", this.inputData.solution);
      newFormData.append("title", this.inputData.title);
      newFormData.append("level", this.inputData.level);
      newFormData.append("id", this.inputData.id);
      if(this.inputData.removeOld) {
        newFormData.append("removeOld", this.inputData.removeOld);
      }
      if(this.inputData.gallery) {
        newFormData.append("gallery", this.inputData.gallery);
      }
      for (var i = 1; i < 6; i++) {
        if(this.inputData['option'+i].option|| this.inputData['option'+i].picture) {
          newFormData.append("option"+i, this.inputData['option'+i].option);
          newFormData.append("key"+i, i);
          newFormData.append("value"+i, this.inputData.answer == i ? 1 : 0);
          newFormData.append("picture"+i, this.inputData['option'+i].picture);
          if(this.inputData['option'+i].removeOld) {
            newFormData.append("removeOld"+i, this.inputData['option'+i].removeOld);
          }
        }
      }
      this.updateTryoutQuestion(newFormData)
      .then(response => {
        this.showLoading = false;
        this.tryoutQuestionDetail();
        this.showMessageBox("Berhasil mengubah data", "Success", "success");
      })
      .catch(error => {
        this.showLoading = false;
        this.showMessageBox(error, "Failed", "success");
      });
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  }
};
</script>

<style></style>
